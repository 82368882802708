<template>
  <v-layout class="mx-4 my-2" align-center>
    <v-icon v-if="value.bullet" class="primary--text smaller-dot mr-4"
      >mdi-circle</v-icon
    >
    <v-layout
      grow
      :class="[
        'zg-pcsd-style py-1',
        $vuetify.breakpoint.xsOnly && 'column',
        value.bullet && 'with-dot',
      ]"
      align-center
    >
      <v-layout :class="!value.builtin && 'non-builtin'">
        <DefaultLabel ellipsis class="mx-4">
          {{ label }}
        </DefaultLabel>
      </v-layout>
      <v-spacer></v-spacer>
      <v-layout justify-end>
        <v-flex shrink v-if="value.builtin">
          <v-switch
            class="zg-text-control mt-0 mb-1 mx-4"
            label="Pokazuj w karcie pacjenta"
            color="expressive_green"
            v-model="value.enabled"
            hide-details
            :ripple="false"
            inset
          ></v-switch>
        </v-flex>
        <DefaultButton
          v-if="!value.builtin"
          icon
          small
          bgColor="transparent"
          color="primary"
          class="my-0"
          @click.prevent="$emit('edit')"
        >
          <v-icon small>mdi-pencil</v-icon>
        </DefaultButton>
        <DefaultButton
          v-if="!value.builtin"
          icon
          small
          bgColor="transparent"
          color="error"
          class="my-0"
          @click.prevent="$emit('remove', value.name)"
        >
          <v-icon small>mdi-trash-can-outline</v-icon>
        </DefaultButton>
        <v-divider
          v-if="!$vuetify.breakpoint.xsOnly || !value.builtin"
          vertical
        ></v-divider>
        <DefaultButton
          :disabled="!upAvailable"
          icon
          small
          bgColor="transparent"
          color="black"
          class="mr-0 my-0"
          @click.prevent="$emit('up')"
        >
          <v-icon small>mdi-chevron-up</v-icon>
        </DefaultButton>
        <DefaultButton
          :disabled="!downAvailable"
          icon
          small
          bgColor="transparent"
          color="black"
          class="ml-0 my-0"
          @click.prevent="$emit('down')"
        >
          <v-icon small>mdi-chevron-down</v-icon>
        </DefaultButton>
      </v-layout>
    </v-layout>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: {},
    upAvailable: {
      default: true,
    },
    downAvailable: {
      default: true,
    },
    label: {},
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
  },
};
</script>

<style scoped lang="scss">
.zg-pcsd-style {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  width: 100% !important;

  &.with-dot {
    width: calc(100% - 26px) !important;
  }
}
.non-builtin {
  width: calc(100% - 80px) !important;
}
.smaller-dot {
  font-size: 10px !important;
}
.zg-text-control::v-deep label {
  color: rgba(0, 0, 0, 0.87);
}
</style>
